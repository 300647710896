import React, { useState,useEffect } from "react";
import "./Admin_login.css";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from "js-cookie";
import { API_URL } from "../../../Config";
import { useNavigate } from "react-router-dom";
const Admin_login = () => {


  const [ data ,setData ] = useState()
  const navigate = useNavigate();
  const handle_change_input = (e)=>{
    setData({...data,[e.target.name] : e.target.value})
  }

  const handle_submit = async(e)=>{
    e.preventDefault()
    await axios.post(`${API_URL}/admin/login`,data).then((res)=>{
      // console.log(res.data)
      if(res.data.status == "success"){
        toast.success("Login Successfull!!!",{ autoClose: 1000 })
        Cookies.set('Authorization',res.data.token)
        navigate("/genre")
      }
      else if(res.data.status == "error" ){
        toast.error(`${res.data.message}`,{ autoClose: 1000 })
      }

    })
  }
  useEffect(() => {
    const Authorization = Cookies.get('Authorization');
    const token = Authorization?.split(" ")[1];
    // console.log(token);
    if(token){
        navigate("/genre")
    }
  }, [])
  
  return (
    <div className="admin_login_bg">
      <ToastContainer/>
      <section className="vh-100">
        <div className="container py-5 h-100">
          <div className="row d-flex align-items-center justify-content-center h-100">
            <div className="col-md-7 col-lg-5 col-xl-5 offset-xl-1">
                  
              <form className="admin_login_form1" onSubmit={(e)=>handle_submit(e)}>
                <div
                  style={{
                    width: "100%",
                    textAlign: "start",
                    marginBottom: "50px",
                    fontSize: "2em",
                    fontWeight: "500",
                    color : "#555555"
                  }}
                >
                  ACCOUNT LOGIN
                </div>
                <div data-mdb-input-init className="form-outline mb-4">
                  <label
                    className="form-label"
                    for="form1Example13"
                    style={{ width: "100%", textAlign: "start" }}
                  >
                    Username
                  </label>
                  <input
                    type="text"
                    id="form1Example13"
                    className="form-control form-control-lg"
                    name="username"
                    onChange={(e)=>handle_change_input(e)}
                  />
                </div>
                <label
                  className="form-label"
                  for="form1Example23"
                  style={{ width: "100%", textAlign: "start" }}
                 
                >
                  Password
                </label>
                <div data-mdb-input-init className="form-outline mb-4">
                  <input
                    type="password"
                    id="form1Example23"
                    className="form-control form-control-lg"
                    name="password"
                    onChange={(e)=>handle_change_input(e)}
                  />
                </div>

                <div className="admin_login_submit_btnContainer">
                <button
                  type="submit"
                  data-mdb-button-init
                  data-mdb-ripple-init
                  className="btn btn-lg admin_login_submitbtn"
                >
                  Login
                </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Admin_login;
