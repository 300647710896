import React, { useState, useEffect } from "react";
import { API_URL, IMG_URL } from "../../../Config";
import Cookies from "js-cookie";
import axios from "axios";
import { AddBox, DeleteForeverOutlined, Edit } from "@mui/icons-material";
import { toast } from "react-toastify";
import { TextField } from "@mui/material";
import Select from "react-select";
const Custom_ads = () => {
  const [data, setData] = useState([]);
  const [editData, seteditData] = useState({});
  const [addData, setaddData] = useState({});
  const headers = {
    "Content-Type": "multipart/form-data",
    Authorization: `${Cookies.get("Authorization")}`,
  };
  const headersJson = {
    "Content-Type": "application/json",
    Authorization: `${Cookies.get("Authorization")}`,
  };
  const options = [
    {
      value: "vertical",
      label: "vertical",
    },
    {
      value: "banner",
      label: "banner",
    },
  ];
  const getAllAds = async () => {
    try {
      const allAds = await axios.get(`${API_URL}/ads`, {
        headers: headersJson,
      });
      console.log(allAds.data.data);
      setData(allAds.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handle_edit_ad = async (data, type) => {
    if (type == "set") {
      seteditData(data);
    } else if (type == "edit") {
      const formData = new FormData();
      formData.append("link", editData.link);
      formData.append("file", editData.image);
      formData.append("type", editData.type);
      const editAd = await axios.put(
        `${API_URL}/ads/${editData._id}`,
        formData,
        { headers: headers }
      );
      if (editAd.data.status == "error") {
        toast.error(editAd.data.message);
      } else {
        toast.success(editAd.data.message);
        window.location.reload();
      }
    } else if (type == "add") {
      const formData = new FormData();
      formData.append("link", addData.link);
      formData.append("file", addData.image);
      formData.append("type", addData.type);
      const addAd = await axios.post(`${API_URL}/ads/`, formData, {
        headers: headers,
      });
      if (addAd.data.status == "error") {
        toast.error(addAd.data.message);
      } else {
        toast.success(addAd.data.message);
        window.location.reload();
      }
    }
  };
  const deleteAd = async (id) => {
    try {
      const deleteAds = await axios.delete(`${API_URL}/ads/${id}`, {
        headers: headersJson,
      });
      if (deleteAds.data.status == "error") {
        toast.error(deleteAds.data.message);
      } else {
        toast.success(deleteAds.data.message);
        window.location.reload();
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAllAds();
  }, []);
  return (
    <div>
      <center
        style={{ width: "90%", margin: "0px auto" }}
        className="mt-2 d-flex justify-content-between"
      >
        <h4>Advertisments</h4>
        <button
          className="btn btn-primary"
          data-bs-toggle="modal"
          data-bs-target="#addads"
          style={{ marginRight: "6px" }}
        >
          <AddBox />
        </button>
      </center>
      <table className="table" style={{ textAlign: "center" }}>
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Image</th>
            <th scope="col">Link</th>
            <th scope="col">Type</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => {
            return (
              <tr key={index} style={{ height: "12vh" }}>
                <th scope="row" style={{ width: "5%" }}>
                  {index + 1}
                </th>
                <td width="50%">
                  <img
                    src={`${IMG_URL}${item.image}`}
                    alt={item.link}
                    style={{
                      width: "100%",
                      height: "12vh",
                      objectFit: "cover",
                    }}
                  />
                </td>
                <td Style="font-family:Roboto Condensed;width:15%">
                  {item.link}
                </td>
                <td Style="font-family:Roboto Condensed;width:5%">
                  {item.type}
                </td>
                <td width="20%">
                  <button
                    className="btn "
                    data-bs-toggle="modal"
                    data-bs-target="#editads"
                    onClick={() => handle_edit_ad(item, "set")}
                    style={{ marginRight: "6px" }}
                  >
                    <Edit />
                  </button>
                  <button
                    className="btn "
                    onClick={() => deleteAd(item._id)}
                    style={{ marginRight: "6px" }}
                  >
                    <DeleteForeverOutlined />
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div
        className="modal fade"
        id="editads"
        tabIndex="-1"
        aria-labelledby="editads"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Edit ADS
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handle_edit_ad("", "edit");
              }}
            >
              <div className="modal-body">
                <TextField
                  id="outlined-basic"
                  label="Link"
                  variant="outlined"
                  fullWidth
                  name="name"
                  onChange={(e) =>
                    seteditData({ ...editData, link: e.target.value })
                  }
                  value={editData.link}
                />
                <Select
                  className="my-3"
                  options={options}
                  isSearchable
                  value={{ value: editData.type, label: editData.type }}
                  onChange={(e) => seteditData({ ...editData, type: e.value })}
                ></Select>
                <input
                  type="file"
                  name="cover"
                  className="form-control"
                  style={{ marginTop: "2rem" }}
                  onChange={(e) =>
                    seteditData({ ...editData, image: e.target.files[0] })
                  }
                />
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button type="submit" className="btn btn-primary">
                  Edit Ad
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="addads"
        tabIndex="-1"
        aria-labelledby="addads"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Add ADS
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handle_edit_ad("", "add");
              }}
            >
              <div className="modal-body">
                <TextField
                  id="outlined-basic"
                  label="Link"
                  variant="outlined"
                  fullWidth
                  name="name"
                  onChange={(e) =>
                    setaddData({ ...addData, link: e.target.value })
                  }
                  value={addData.link}
                />
                <Select
                  className="my-3"
                  options={options}
                  isSearchable
                  onChange={(e) => setaddData({ ...addData, type: e.value })}
                ></Select>
                <input
                  type="file"
                  name="cover"
                  className="form-control"
                  style={{ marginTop: "2rem" }}
                  onChange={(e) =>
                    setaddData({ ...addData, image: e.target.files[0] })
                  }
                />
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button type="submit" className="btn btn-primary">
                  Add Ad
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Custom_ads;
