import axios from "axios";
import React, { useEffect, useState } from "react";
import { API_URL } from "../../../Config";

const Privacy_Policies = () => {
  const [get_data, set_data] = useState("");

  const handle_get_data = async () => {
    await axios.get(`${API_URL}/terms/`).then((res) => {
      if (res.data.status == "success") {
        set_data(res.data.data.termsCondition);
      }
    });
  };

  useEffect(() => {
    handle_get_data();
  }, []);

  return (
    <div>
      <div
        style={{
          fontFamily: "poppins",
          fontSize: "30px",
          color: "white",
          marginLeft: "2%",
          
        }}
      >
        Privacy Policy{" "}
      </div>
      <div
        style={{
          width: "100%",
          padding: "50px",
          color: "white",
          whiteSpace: "break-spaces",
        }}
      >
        {get_data}
      </div>
    </div>
  );
};

export default Privacy_Policies;
