import React, { useEffect } from "react";
import Dashboard_Slider from "../Dashboard_Slider/Dashboard_Slider";
import StaffPicks from "../Staff_Picks/StaffPicks";
import RecentlyAdded from "../Recently_Added/RecentlyAdded";
import User_Comic_Viewer from "../Comic_Viewer/User_Comic_Viewer";
import GenreSliders from "../GenreSliders/GenreSliders";
import axios from "axios";
import { API_URL } from "../../../Config";
import Top10 from "../Top10/Top10";
import Adsense from "../adSense/Adsense";
import { useNavigate } from "react-router-dom";

const User_Dashboard = () => {
  const navigate = useNavigate();
  const checkVisits = async () => {
    if (localStorage.getItem("visited") === null) {
      const check = await axios.get(`${API_URL}/user/visit`);
      if (!check.data.visited) {
        localStorage.setItem("visited", true);
        navigate("/intro", { replace: true });
      } else {
        localStorage.setItem("visited", true);
      }
    }
  };
  useEffect(() => {
    checkVisits();
  }, []);

  return (
    <div>
      <Dashboard_Slider />

      <center>
        <div style={{marginTop:"20px" , marginBottom :"40px"}} className=" dashboard_ad_container1">
          <Adsense responsive={true} dataAdSlot="" />
        </div>
      </center>

      <Top10 />
      <StaffPicks />
      <RecentlyAdded />
      <center>
        <div style={{marginTop:"8rem" , marginBottom:"-3rem"}} className=" dashboard_ad_container2">
          <Adsense responsive={true} dataAdSlot="" />
        </div>
      </center>
      <GenreSliders />
      {/* <User_Comic_Viewer/> */}
    </div>
  );
};

export default User_Dashboard;
