import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import { IMG_URL, API_URL } from "../../../Config";
import { Pagination } from "@mui/material";
import axios from "axios";
import "./ComicLibrary.css";
import google_icon from "./Assets/search.png";
import { useNavigate } from "react-router-dom";
import ComicSwirl from "../loaders/ComicSwirl";
const ComicLibrary = () => {
  const [comic, setcomic] = useState([]);
  const [page, setpage] = useState(0);
  const [pageno, setpageno] = useState(1);
  const [comics, setcomics] = useState([]);
  const [load, setload] = useState(false);
  const navigate = useNavigate();
  const getUserLibrary = async () => {
    setload(true);
    if (Cookies.get("UAuthorization")) {
      const data = await axios.get(`${API_URL}/user/save`, {
        headers: {
          Authorization: Cookies.get("UAuthorization"),
        },
      });
      if (data.data.data) {
        // console.log(data.data.data);
        setcomic(data.data.data);
        setpage(data.data.count < 30 ? 1 : Math.ceil(data.data.count / 30));
        setcomics(data.data.data.slice((pageno - 1) * 30, 30 * pageno));
      }
    }
    setload(false);
  };
  const changePage = (event, value) => {
    // console.log(value);
    setpageno(value);
    setcomics(comic.slice((value - 1) * 30, 30 * value));
    // getUserLibrary();
  };
  const addHistory = async (id, title) => {
    if (Cookies.get("UAuthorization")) {
      await axios.post(
        `${API_URL}/user/addHistory`,
        { id: id },
        { headers: { Authorization: Cookies.get("UAuthorization") } }
      );
    }
    navigate(`/comic/${title}`);
  };
  useEffect(() => {
    getUserLibrary();
  }, []);
  return (
    <div className="text-white">
      <div className="mt-5 mb-3 pt-2">
        <div
          className="back text-white d-flex "
          style={{ width: "90%", margin: "0px auto" }}
        >
          <h2> Your Library</h2>
        </div>
        {load ? (
          <center>
            <ComicSwirl />
          </center>
        ) : (
          <div>
            {" "}
            <div className="showLibrary">
              {comics != [] ? (
                comics.map((data, index) => {
                  return (
                    <div
                      className="grid-item"
                      onClick={() => addHistory(data._id, data.title)}
                      data-text={data.title}
                    >
                      <img src={`${IMG_URL}${data.coverImage}`} />
                    </div>
                  );
                })
              ) : (
                <h3
                  className=" text-center mt-5 text-capitalize"
                  style={{
                    color: "rgb(218, 142, 231)",
                    fontFamily: "monospace",
                  }}
                >
                  Please Add Comic in Library
                </h3>
              )}
            </div>
            <div className="text-white text-center pagination">
              {page > 0 ? (
                <Pagination
                  count={page}
                  page={pageno}
                  defaultPage={1}
                  onChange={changePage}
                  color="secondary"
                  showFirstButton
                  showLastButton
                />
              ) : (
                ""
              )}
            </div>
          </div>
        )}
        {!Cookies.get("UAuthorization") && (
          <div>
            <center style={{ marginTop: "6%" }}>
              <button
                className="signin_btn"
                onClick={() =>
                  (window.location.href = `${API_URL}/user/auth/google`)
                }
              >
                {" "}
                <img
                  src={google_icon}
                  alt=""
                  style={{ width: "25px", height: "25px" }}
                />{" "}
                Sign In
              </button>
              <button
                className="signin_btn"
                onClick={() =>
                  (window.location.href = `${API_URL}/user/auth/google`)
                }
              >
                {" "}
                <img
                  src={google_icon}
                  alt=""
                  style={{ width: "25px", height: "25px" }}
                />{" "}
                Sign Up
              </button>
              <h3
                className=" text-center mt-5 text-capitalize"
                style={{ color: "rgb(218, 142, 231)", fontFamily: "monospace" }}
              >
                For The Watching Library Please Sign In
              </h3>
            </center>
          </div>
        )}
      </div>
    </div>
  );
};

export default ComicLibrary;
