import React, { useEffect, useContext, useState } from "react";
import { a, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import MenuIcon from "@mui/icons-material/Menu";
import { PermIdentity, SaveAltOutlined } from "@mui/icons-material";
import "../User_sideBar/SideBar.css";
import User_Dashboard from "../User_Dashboard/User_Dashboard";
import Show_Comic from "../Show_Comic/ShowComic";
import User_Comic_Viewer2 from "../Comic_Viewer/Comic2";
import { Menu, MenuItem } from "@mui/material";
import ComicViewerComponent from "../Comic_Viewer/Comic3";
import { API_URL } from "../../../Config";
import ReadingHistory from "../Reading_History/ReadingHistory";
import ComicLibrary from "../ComicLibrary/ComicLibrary";
import LatestUpdated from "../LatestUpdated/LatestUpdated";
import RecentlyAddedAll from "../RecentlyAddedAll/RecentlyAddedAll";
import AdvancedSearch from "../AdvacedSearch/AdvancedSearch";
import ComicByGenre from "../AllComicGenre/ComicByGenre";
import SearchIcon from "@mui/icons-material/Search";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Term_And_Conditions from "../Term_And_Condition And Other/Term_And_Conditions";
import Privacy_Policies from "../Term_And_Condition And Other/Privacy_Policies";
import useButtonClick from "../utils/utils";
import google_icon from "../ComicLibrary/Assets/search.png";
import LOGO from "../../../assets/1.png";
import { ScrollContext } from "../utils/ScrollContext";
const SideBar = () => {
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [isInstallable, setIsInstallable] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  // const topRef = useRef(null);
  const [isSafari, setisSafari] = useState(true);
  const [click14, clickButton14] = useButtonClick("button14");
  const [click15, clickButton15] = useButtonClick("button15");
  const [click16, clickButton16] = useButtonClick("button16");
  const [click17, clickButton17] = useButtonClick("button17");
  const [click18, clickButton18] = useButtonClick("button18");
  const [click19, clickButton19] = useButtonClick("button19");
  const [click20, clickButton20] = useButtonClick("button20");
  const [click21, clickButton21] = useButtonClick("button21");
  const [click22, clickButton22] = useButtonClick("button22");
  const navigate = useNavigate();
  const scrollRef = useContext(ScrollContext);
  useEffect(() => {
    const handleBeforeInstallPrompt = (event) => {
      event.preventDefault();
      setDeferredPrompt(event);
      setIsInstallable(true);
    };

    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener(
        "beforeinstallprompt",
        handleBeforeInstallPrompt
      );
    };
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleInstallClick = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          // console.log("User accepted the A2HS prompt");
        } else {
          // console.log("User dismissed the A2HS prompt");
        }
        setDeferredPrompt(null);
        setIsInstallable(false);
      });
    }
  };

  const Login = () => {
    window.location.href = `${API_URL}/user/auth/google`;
  };
  const Logout = () => {
    localStorage.clear();
    Cookies.remove("UAuthorization");
    navigate("/", { replace: true });
  };
  useEffect(() => {
    // handle_get_admin_details();
    // console.log("scacscsadcsdcsdc",admin_data)
    const handleSidebarToggle = () => {
      const wrapper = document.getElementById("wrapper");
      const sidebarToggle = document.getElementById("sidebar-toggle");

      if (wrapper && sidebarToggle) {
        wrapper.classList.toggle("toggled");
      }
    };

    const sidebarToggle = document.getElementById("sidebar-toggle");
    if (sidebarToggle) {
      sidebarToggle.addEventListener("click", handleSidebarToggle);
    }
    function isSafari() {
      var ua = navigator.userAgent.toLowerCase();
      return ua.indexOf("safari") !== -1 && ua.indexOf("chrome") === -1;
    }

    if (isSafari()) {
      setisSafari(false);
    }
    // topRef.current.scrollIntoView({ behavior: 'smooth' });

    return () => {
      if (sidebarToggle) {
        sidebarToggle.removeEventListener("click", handleSidebarToggle);
      }
    };
  }, []); // Empty dependency array to ensure the effect runs only once after initial render

  const handleScrollTop = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const location = useLocation();

  const hideSideNav = () => {
    if (window.innerWidth < 827) {
      const toggleSelect = document.getElementById("wrapper");
      toggleSelect.classList.remove("toggled");
    }
  };

  return (
    <div className="user_side_nav_bar">
      <div id="wrapper">
        <aside id="sidebar-wrapper">
          <div className="user-sidebar-brand my-0">
            {/* <h2>Logo</h2> */}

            {/* <a href="/dashboard"> <img src={logo} style={{width:"8vw",height:"3vw"}}/></a> */}
            <a
              href="/"
              style={{
                textDecoration: "none",
                color: "white",
                fontFamily: "poppins",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <img src={LOGO} alt="logo" Style="width: 23%;" className="" />
                <div
                  style={{
                    fontSize: "1em",
                    color: " rgb(187 94 241)",
                    fontWeight: 800,
                  }}
                >
                  MANGA<span className="text-white">INSPO</span>
                </div>
              </div>
            </a>
          </div>
          <ul className="sidebar-nav" style={{ marginTop: "1.5vw" }}>
            <li
              className="active"
              onClick={() => {
                clickButton14();
              }}
            >
              <a href="/">
                <div>Home</div>
              </a>
            </li>
            <li
              className="active"
              onClick={() => {
                clickButton15();
              }}
            >
              <a href="/my/library">
                <div>Library</div>
              </a>
            </li>
            <li
              className="active"
              onClick={() => {
                clickButton16();
              }}
            >
              <a href="/my/history">
                <div>Reading History</div>
              </a>
            </li>
            <li
              className="active"
              onClick={() => {
                clickButton17();
              }}
            >
              <a href="/latest">
                <div>Latest Updated</div>
              </a>
            </li>
            <li
              className="active"
              onClick={() => {
                clickButton18();
              }}
            >
              <a href="/recently">
                <div>Recently Added</div>
              </a>
            </li>
            <li
              className="active"
              onClick={() => {
                clickButton19();
              }}
            >
              <a href="/search">
                <div>Advaced Search</div>
              </a>
            </li>{" "}
            <li
              className="active"
              onClick={() => {
                clickButton20();
              }}
            >
              <a href="/term_and_condition">
                <div style={{ fontSize: ".96em" }}>Terms & Conditions</div>
              </a>
            </li>{" "}
            <li
              className="active"
              onClick={() => {
                clickButton21();
              }}
            >
              <a href="/privacy_policies">
                <div>Privacy Policy</div>
              </a>
            </li>
            {/* <li className="active" style={{marginTop : "60%"}} onClick={()=>{clickButton22()}}>
              <a href="/search" >
                <div>
                  {isInstallable && (
                    <div className="install-button-container">
                      <button
                        className="install-button btn"
                        onClick={handleInstallClick}
                        style={{ color: "green" }}
                      >
                       <SaveAltOutlined
                        style={{ fontSize: "1.5em" }}
                      />  Install App
                      </button>
                    </div>
                  )}
                </div>
              </a>
            </li>*/}
          </ul>
        </aside>
        <div id="navbar-wrapper">
          <nav className="navbar navbar-inverse">
            <div className="container-fluid" style={{ padding: "0" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  xIndex: 5,
                }}
                className="navbar-header nnav1"
              >
                <a className="navbar-brand" id="sidebar-toggle">
                  <i className="fa fa-bars">
                    <MenuIcon style={{ color: "white", cursor: "pointer" }} />
                  </i>
                </a>

                <div
                  className="navIcon"
                  style={{
                    position: "absolute",
                    right: "9%",
                    display: "flex",
                    gap: "15px",
                  }}
                >
                  <a href="/search">
                    <SearchIcon style={{ fontSize: "1.1em", color: "white" }} />
                  </a>
                  {isInstallable && (
                    <a onClick={handleInstallClick}>
                      <SaveAltOutlined
                        style={{ fontSize: "1.1em", color: "white" }}
                      />
                    </a>
                  )}

                  <div>
                    <div className="text-white" Style="cursor:pointer">
                      <PermIdentity
                        onClick={handleClick}
                        style={{ fontSize: "1.1em" }}
                      />
                    </div>
                    <Menu
                      id="demo-positioned-menu"
                      aria-labelledby="demo-positioned-button"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      className="dark"
                    >
                      {!Cookies.get("UAuthorization") ? (
                        <div
                          style={{
                            color: "white",
                            background: " rgb(40 44 52) ",
                          }}
                        >
                          <MenuItem onClick={() => Login()}>
                            {" "}
                            <img
                              src={google_icon}
                              alt=""
                              style={{
                                width: "20px",
                                height: "20px",
                                marginRight: "10px",
                              }}
                            />{" "}
                            Sign In
                          </MenuItem>
                          <MenuItem onClick={() => Login()}>
                            {" "}
                            <img
                              src={google_icon}
                              alt=""
                              style={{
                                width: "20px",
                                height: "20px",
                                marginRight: "10px",
                              }}
                            />{" "}
                            Sign Up
                          </MenuItem>
                        </div>
                      ) : (
                        <div style={{ background: "#282c34", color: "white" }}>
                          <MenuItem onClick={() => Logout()}>Logout</MenuItem>
                        </div>
                      )}
                    </Menu>
                  </div>
                  <a>
                    {/* <InstallDesktopIcon style={{fontSize : "1.2em" , color : "white"}}/> */}
                  </a>
                </div>
              </div>
            </div>
          </nav>
        </div>
        <section
          id="content-wrapper"
          style={{
            backgroundColor: "#191A1C",
            height: "100vh",
            overflowX: "hidden",
          }}
          onClick={() => hideSideNav()}
        >
          <div id="top" ref={scrollRef}></div>

          <div id="goingTop">
            <button
              className="btn_top_scroll"
              onClick={handleScrollTop}
              style={{ bottom: isSafari ? "10px" : "35px" }}
            >
              <KeyboardArrowUpIcon />
            </button>
          </div>
          <Routes>
            <Route path="/" element={<User_Dashboard />} />
            <Route path="/comic_3" element={<ComicViewerComponent />} />
            <Route
              path="/comic_viewer2/:ids"
              element={<User_Comic_Viewer2 />}
            />
            <Route path="/comic/:ids" element={<Show_Comic />} />
            <Route path="/my/history" element={<ReadingHistory />} />
            <Route path="/my/library" element={<ComicLibrary />} />
            <Route path="/latest" element={<LatestUpdated />} />
            <Route path="/recently" element={<RecentlyAddedAll />} />
            <Route path="/search" element={<AdvancedSearch />} />
            <Route path="/genre/:name" element={<ComicByGenre />} />
            {/* <Route path="/intro" element={<IntroPage />} /> */}
            <Route
              path="/term_and_condition"
              element={<Term_And_Conditions />}
            />
            <Route path="/privacy_policies" element={<Privacy_Policies />} />
          </Routes>
        </section>
      </div>
    </div>
  );
};

export default SideBar;
