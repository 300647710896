import React, { useState, useEffect } from "react";
import "./StaffPicks.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from "axios";
import { API_URL, IMG_URL } from "../../../Config";
import {
  ArrowBackIosNewOutlined,
  ArrowCircleLeftSharp,
  ArrowCircleRightSharp,
  EastSharp,
} from "@mui/icons-material";
import Cookies from "js-cookie";
import useButtonClick from "../utils/utils";
import { useNavigate } from "react-router-dom";
import ComicSwirl from "../loaders/ComicSwirl";

const StaffPicks = () => {
  const [comics, setComics] = useState([]);
  const [click13, clickButton13] = useButtonClick("button13");
  const navigate = useNavigate();
  const sliderSettings = {
    dots: true,
    infinite: false,
    speed: 200,
    slidesToShow: 5,
    slidesToScroll: 1,
    arrows: false, // Remove arrows

    responsive: [
      {
        breakpoint: 2560,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
          autoScroll: true,
          autoplay: true,
          speed: 1000,
          autoplaySpeed: 5000,
          cssEase: "linear",
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
          autoScroll: true,
          autoplay: true,
          speed: 1000,
          autoplaySpeed: 5000,
          cssEase: "linear",
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
          autoplay: true,
          speed: 1000,
          autoplaySpeed: 5000,
          cssEase: "linear",
        },
      },
    ],
  };

  const comicsData = async () => {
    try {
      const data = await axios.get(`${API_URL}/comic/latest/updated`);
      // console.log(data.data);
      setComics(data.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  const addHistory = async (id,title) => {
    if (Cookies.get("UAuthorization")) {
      await axios.post(
        `${API_URL}/user/addHistory`,
        { id: id },
        { headers: { Authorization: Cookies.get("UAuthorization") } }
      );
    }
    navigate(`/comic/${title}`);
  };
  useEffect(() => {
    comicsData();
  }, []);

  return (
    <div className="container text-white slider-container my-4">
      <div
        className="d-flex justify-content-between"
        style={{ width: "98%", margin: "10px auto" }}
      >
        <h3>Latest Updated</h3>
        <span
          style={{ color: "White", cursor: "pointer" }}
          onClick={() => navigate("/latest")}
        >
          See All
          <EastSharp style={{ cursor: "pointer" }} />
        </span>
      </div>
      {comics.length > 0 ? (
        <Slider {...sliderSettings}>
          {comics.slice(0,10).map((card, index) => (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => addHistory(card._id,card.title)}
            >
              <div key={index} className="card">
                <img
                  src={`${IMG_URL}${card.coverImage}`}
                  className="card-img-top"
                  alt="..."
                />
                <div className="card-body">
                  <p className="card-text h-75 overflow-y-auto">
                    {card.description}
                  </p>
                  <div className="d-flex pt-3">
                    <button
                      className="btn btn-light btn-sm me-2"
                      onClick={() => {
                        clickButton13();
                      }}
                    >
                      <ArrowCircleRightSharp />
                    </button>
                  </div>
                </div>
              </div>
              <h6 className="comicName mt-2 text-sm text-white line-clamp-2">
                {card.title}
              </h6>
            </div>
          ))}
        </Slider>
      ) : (
        <center><ComicSwirl/></center>
      )}
    </div>
  );
};
export default StaffPicks;
