import React from "react"
import {CircleLoader} from 'react-spinners'
function ComicSwirl() {
  return (
    <CircleLoader
      color="#da8ee7"
      cssOverride={{}}
      loading
      size={40}
      speedMultiplier={0.8}
    />
  );
}

export default ComicSwirl;
