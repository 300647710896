import axios from "axios";
import React, { useEffect, useState } from "react";
import { API_URL } from "../../../Config";

const Term_And_Conditions = () => {

  const [get_data , set_data] = useState("")

  const handle_get_data = async()=>{
    await axios.get(`${API_URL}/terms/`).then((res)=>{
      if(res.data.status == "success" ){
        set_data(res.data.data.copyright)
      }
    })
  }

  useEffect(()=>{
    handle_get_data();
  },[])

  return (
    <div>
      <div style={{ fontFamily: "poppins", fontSize: "30px", color: "white" , marginLeft : "2%" }}>
        Terms And Conditions
      </div>
      <div style={{width : "100%", padding : "50px" , color : "white" , whiteSpace : "break-spaces"}}>
        {get_data}
      </div>
    </div>
  );
};

export default Term_And_Conditions;
