import React, { useEffect, useState } from "react";
import "./Admin_SideNav.css";
import {
  a,
  Route,
  Router,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import $ from "jquery";
import axios from "axios";
import Cookies from "js-cookie";
import Admin_Genre from "../Admin_Genre/Admin_Genre";
import MenuIcon from "@mui/icons-material/Menu";
import Admin_Add_Comics from "../Admin_Add_Comics/Admin_Add_Comics";
import ComicChapter from "../Comic_Chapter/comicChapter";
import {
  Book,
  CopyrightRounded,
  GridView,
  Home,
  LockResetSharp,
  LogoutOutlined,
} from "@mui/icons-material";
import Copyright_Terms from "../Copyright_Terms/Copyright_Terms";
import { API_URL } from "../../../Config";
import Custom_ads from "../Custom_ADS/Custom_ads";
import ResetPassword from "../Reset_Password/ResetPassword";
const Admin_SideNav = () => {
  const [admin_data, set_Admin_data] = useState([]);

  const handle_get_admin_details = async () => {
    await axios
      .post(`${API_URL}/get_admin_details`, {
        // .post(`${API_URL}/get_admin_details`, {
        id: Cookies.get("admin_id"),
      })
      .then((res) => {
        if (res.status == 200) {
          // console.log(res.data)
          set_Admin_data(res.data[0]);
          //  console.log("=============",admin_data)
        }
      });
  };
  const Logout = () => {
    localStorage.clear();
    Cookies.remove("Authorization");
    window.location.replace("/");
  };
  useEffect(() => {
    // handle_get_admin_details();
    // console.log("scacscsadcsdcsdc",admin_data)

    const handleSidebarToggle = () => {
      const wrapper = document.getElementById("wrapper");
      const sidebarToggle = document.getElementById("sidebar-toggle");

      if (wrapper && sidebarToggle) {
        wrapper.classList.toggle("toggled");
      }
    };

    const sidebarToggle = document.getElementById("sidebar-toggle");
    if (sidebarToggle) {
      sidebarToggle.addEventListener("click", handleSidebarToggle);
    }

    return () => {
      if (sidebarToggle) {
        sidebarToggle.removeEventListener("click", handleSidebarToggle);
      }
    };
  }, []); // Empty dependency array to ensure the effect runs only once after initial render

  return (
    <div>
      <div id="wrapper">
        <aside id="sidebar-wrapper">
          <div className="sidebar-brand">
            {/* <h2>Logo</h2> */}

            {/* <a href="/dashboard"> <img src={logo} style={{width:"8vw",height:"3vw"}}/></a> */}
          </div>
          <ul className="sidebar-nav" style={{ marginTop: "1.5vw" }}>
            <li className="active">
              <a href="/genre">
                <i className="fa fa-home">
                  {" "}
                  <Home />{" "}
                </i>
                <div>Dashboard</div>
              </a>
            </li>
            <li className="active">
              <a href="/add_comic">
                <i className="fa fa-home">
                  {" "}
                  <Book />
                </i>
                <div>Comics</div>
              </a>
            </li>
            <li className="active">
              <a href="/terms">
                <i className="fa fa-home">
                  {" "}
                  <CopyrightRounded />
                </i>
                <div>Copyright&Conditions</div>
              </a>
            </li>
            <li className="active">
              <a href="/ads">
                <i className="fa fa-home">
                  {" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    class="bi bi-badge-ad"
                    viewBox="0 0 16 16"
                  >
                    <path d="m3.7 11 .47-1.542h2.004L6.644 11h1.261L5.901 5.001H4.513L2.5 11zm1.503-4.852.734 2.426H4.416l.734-2.426zm4.759.128c-1.059 0-1.753.765-1.753 2.043v.695c0 1.279.685 2.043 1.74 2.043.677 0 1.222-.33 1.367-.804h.057V11h1.138V4.685h-1.16v2.36h-.053c-.18-.475-.68-.77-1.336-.77zm.387.923c.58 0 1.002.44 1.002 1.138v.602c0 .76-.396 1.2-.984 1.2-.598 0-.972-.449-.972-1.248v-.453c0-.795.37-1.24.954-1.24z" />
                    <path d="M14 3a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zM2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2z" />
                  </svg>
                </i>
                <div>Advertisments</div>
              </a>
            </li>
            <li className="active">
              <a href="/reset">
                <i className="fa fa-home">
                  {" "}
                  <LockResetSharp />
                </i>
                <div>Reset Password</div>
              </a>
            </li>
            <li className="active">
              <a href="" onClick={() => Logout()}>
                <i className="fa fa-home">
                  <LogoutOutlined />
                </i>
                <div>Logout</div>
              </a>
            </li>
          </ul>
        </aside>
        <div id="navbar-wrapper">
          <nav className="navbar navbar-inverse">
            <div className="container-fluid">
              <div className="navbar-header nnav1">
                <a className="navbar-brand" id="sidebar-toggle">
                  <i className="fa fa-bars">
                    <MenuIcon />
                  </i>
                </a>
              </div>
            </div>
          </nav>
        </div>
        <section id="content-wrapper">
          <Routes>
            <Route path="/genre" element={<Admin_Genre />} />
            <Route path="/add_comic" element={<Admin_Add_Comics />} />
            <Route path="/chapter" element={<ComicChapter />} />
            <Route path="/terms" element={<Copyright_Terms />} />
            <Route path="/ads" element={<Custom_ads />} />
            <Route path="/reset" element={<ResetPassword />} />
          </Routes>
        </section>
      </div>
    </div>
  );
};

export default Admin_SideNav;
