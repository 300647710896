import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ScrollContext } from "./ScrollContext";

const useButtonClick = (storageKey) => {
  const [count, setCount] = useState(() => {
    const sessionStore = sessionStorage.getItem(storageKey);
    return sessionStore ? parseInt(sessionStore) : 0;
  });
  const navigate = useNavigate();
  useEffect(() => {
    sessionStorage.setItem(storageKey, count.toString());
    if (count == 3) {
      setCount(0);
      sessionStorage.setItem(storageKey, 0);
      sessionStorage.setItem("show_popup", "true");
      navigate(0);
      // setShow(true)
      // navigate("/intro",{replace:true})
    }
  }, [count]);

  const handleTheClick = () => {
    setCount((prevCount) => prevCount + 1);
  };

  return [count, handleTheClick];
};
export default useButtonClick;
