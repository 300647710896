// import React from 'react'
// import { useRef } from 'react';
// import { useEffect } from 'react';
// import { useState } from 'react';
// import $ from 'jquery'
// import axios from 'axios';
// import Cookies from 'js-cookie';
// import DataTable from 'datatables.net-dt';

// const Admin_Genre = () => {

//     const [get_genre,set_genre] = useState([])
//     const tableRef = useRef(null);

//     const handle_get_genre = async()=>{
//         const headers = {
//             'Content-Type': 'application/json',
//             'Authorization': `${Cookies.get('token')}`
//           };

//         await axios.get(`${API_URL}/genre`,{ headers : headers }).then((res)=>{
//             console.log(res.data)
//             if(res.data.status == 'success'){
//                 console.log(res.data.data)
//                 set_genre(res.data.data)
//             }
//         })
//     }

//    useEffect(()=>{
//     // let table = new DataTable('#myTable');
//     handle_get_genre()
//    },[])

//     useEffect(() => {
//         if (get_genre.length > 0 && !tableRef.current) {
//           // Initialize the DataTable with checkboxes
//           const tableInstance = $("#myTable").DataTable({
//             responsive: true,
//             data: get_genre,
//             columns: [
//               { title: "_id", data: "_id" },
//               { title: "Name", data: "name" },
//               // Add more columns based on your data
//             ],
//           });
//           tableRef.current = tableInstance;
//         }

//         return () => {
//           if (tableRef.current) {
//             tableRef.current.destroy();
//             tableRef.current = null;
//           }
//         };
//       }, [get_genre]);

//   return (
//     <div>
//          <div style={{ width: "100%" }}>
//             <table
//               id="myTable"
//               className="display table"
//               style={{ width: "100%" }}
//             >
//               <thead>
//                 <tr>
//                   <th>category</th>

//                   {/* <th>State</th>
//             <th>City</th>
//             <th>Address</th>
//             <th>Area</th>
//             <th>Phone</th>
//             <th>WhatsApp</th>
//             <th>Message</th>
//             <th>Reference</th> */}
//                 </tr>
//               </thead>
//             </table>
//           </div>
//     </div>
//   )
// }

// export default Admin_Genre

import React, { useRef, useEffect, useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import DataTable from "datatables.net-dt"; // Assuming DataTable is correctly imported
import $ from "jquery";
import "datatables.net-responsive";
import "./Admin_Genre.css";
import TextField from "@mui/material/TextField";
import { toast } from "react-toastify";
import ReactDOM from "react-dom";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { API_URL } from "../../../Config";
import { useNavigate } from "react-router-dom";
import Loader from "../Loader/Loader";
const Admin_Genre = () => {
  const [get_genre, set_genre] = useState([]);
  const [loader, setLoader] = useState(false);

  const tableRef = useRef(null);
  const navigate = useNavigate();
  const handle_get_genre = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `${Cookies.get("Authorization")}`,
    };

    try {
      const response = await axios.get(`${API_URL}/genre`, {
        headers,
      });
      if (response.data.status === "success") {
        set_genre(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching genre data:", error);
    }
  };

  useEffect(() => {
    const Authorization = Cookies.get("Authorization");
    const token = Authorization?.split(" ")[1];
    // console.log(token);
    if (token) {
      handle_get_genre();
    } else {
      navigate("/admin_login");
    }
  }, []);

  useEffect(() => {
    if (get_genre.length > 0 && !tableRef.current) {
      // Initialize the DataTable with checkboxes
      const tableInstance = $("#myTable").DataTable({
        responsive: true,
        data: get_genre,
        columns: [
          { title: "Name", data: "name" },
          { title: "Actions", data: null },
          // Add more columns based on your data
        ],
        columnDefs: [
          {
            targets: -1,
            data: null,
            defaultContent: "",
            orderable: false,
            createdCell: function (td, cellData, rowData, row, col) {
              ReactDOM.render(
                <>
                  <button
                    className="btn btn-primary "
                    data-bs-toggle="modal"
                    data-bs-target="#editgenre"
                    onClick={() => handle_get_genre_name(rowData)}
                    style={{ marginRight: "6px" }}
                  >
                    <EditIcon />
                  </button>

                  <button
                    className="btn btn-danger"
                    onClick={(e) => {
                      handle_delete_genre(e, rowData._id);
                    }}
                  >
                    <DeleteIcon />
                  </button>
                </>,
                td
              );
            },
          },
        ],
      });
      tableRef.current = tableInstance;
    }

    return () => {
      if (tableRef.current) {
        tableRef.current.destroy();
        tableRef.current = null;
      }
    };
  }, [get_genre]);

  const genreRef = useRef();

  const handle_submit_genre = (e) => {
    e.preventDefault();
    setLoader(true);
    const headers = {
      "Content-Type": "application/json",
      Authorization: `${Cookies.get("Authorization")}`,
    };

    axios
      .post(`${API_URL}/genre`, { genre: data.name }, { headers: headers })
      .then((res) => {
        if (res.data.status == "success") {
          toast.success(`${res.data.message}`);
          navigate(0);
          setLoader(false);
        } else {
          toast.success(`${res.data.message}`);
    setLoader(false);

        }
      });
  };

  const handle_delete_genre = (e, id) => {
    e.preventDefault();
    setLoader(true);

    const headers = {
      "Content-Type": "application/json",
      Authorization: `${Cookies.get("Authorization")}`,
    };

    const ans = window.confirm("Are You Sure...");
    if (ans) {
      axios
        .delete(`${API_URL}/genre/${id}`, {
          headers: headers,
        })
        .then((res) => {
          if (res.data.status == "success") {
            toast.success(`${res.data.message}`);
            navigate(0);
            setLoader(false);
          } else {
            toast.success(`${res.data.message}`);
    setLoader(false);

          }
        });
    }
  };

  const handle_get_genre_name = (name) => {
    // console.log(name);
    setData(name);
  };
  const handle_edit_genre = (e) => {
    e.preventDefault();
    setLoader(true);

    const headers = {
      "Content-Type": "application/json",
      Authorization: `${Cookies.get("Authorization")}`,
    };
    axios
      .put(
        `${API_URL}/genre/${data._id}`,
        { genre: data.name },
        { headers: headers }
      )
      .then((res) => {
        if (res.data.status == "success") {
          toast.success(`${res.data.message}`);
          navigate(0);
          setLoader(false);
        } else {
          toast.success(`${res.data.message}`);
    setLoader(false);

        }
      });
  };

  const [data, setData] = useState({ name: "" });

  const handle_change_input = async (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  return (
    <div className="container">
      {loader ? <Loader /> : null}
      <div className="row">
        <div className="col" style={{ paddingTop: "20px" }}>
          <h2>
            {" "}
            <button
              type="button"
              className="btn btn-primary add_genre_btn"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
            >
              Add New Genre
            </button>
            Genre
          </h2>
          <table
            id="myTable"
            className="table table-striped table-bordered"
            style={{ width: "100%" }}
          >
            <thead>
              <tr>
                <th>Genre</th>
                {/* Add more table headers if needed */}
              </tr>
            </thead>
          </table>
        </div>

        {/* <!-- Modal --> */}
        <div
          className="modal fade"
          id="exampleModal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Add Genre
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <form
                onSubmit={(e) => {
                  handle_submit_genre(e);
                }}
              >
                <div className="modal-body">
                  <TextField
                    id="outlined-basic"
                    label="Genre"
                    variant="outlined"
                    fullWidth
                    name="name"
                    onChange={(e) => handle_change_input(e)}
                  />
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                  <button type="submit" className="btn btn-primary">
                    Add Genre
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        {/* <!--Edit Genre Modal --> */}
        <div
          className="modal fade"
          id="editgenre"
          tabIndex="-1"
          aria-labelledby="editgenre"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Edit Genre
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <form
                onSubmit={(e) => {
                  handle_edit_genre(e);
                }}
              >
                <div className="modal-body">
                  <TextField
                    id="outlined-basic"
                    label="Genre"
                    variant="outlined"
                    fullWidth
                    name="name"
                    onChange={(e) => handle_change_input(e)}
                    value={data.name}
                  />
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                  <button type="submit" className="btn btn-primary">
                    Edit Genre
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Admin_Genre;
