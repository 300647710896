import react, { useEffect, useState, useContext } from "react";
import { ToastContainer } from "react-toastify";
import Admin_login from "./components/Admin/Admin_login/Admin_login";
import "./App.css";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import Admin_SideNav from "./components/Admin/Admin_SideNav/Admin_SideNav";
import SideBar from "./components/users/User_sideBar/SideBar";
import IntroPage from "./components/users/IntroPage/IntroPage";
import { ScrollContext } from "./components/users/utils/ScrollContext";
import Adsense from "./components/users/adSense/Adsense";
import useButtonClick from "./components/users/utils/utils";
import AdSenseModal from "./components/users/adSense/AdsenseModel";

function App() {
  const [showPopup, setShowPopup] = useState(
    sessionStorage.getItem("show_popup") === "true"
  );
  const navigate = useNavigate();
  const location = useLocation();
  const isAdmin = Cookies.get("Authorization");
  const isOnAdminLoginPage = location.pathname === "/admin_login";
  const isOnIntroPage = location.pathname === "/intro";
  const adminRoutes = ["/genre", "/add_comic", "/chapter", "/terms", "/ads","/reset"];
  const scrollRef = useContext(ScrollContext);
  useEffect(() => {
    // window.location.replace("#top");
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: "smooth" });
    }
    if (isAdmin && !adminRoutes.includes(location.pathname)) {
      navigate("/genre");
    }
  }, [isAdmin, navigate]);
  useEffect(() => {
    const handleStorageChange = () => {
      setShowPopup(sessionStorage.getItem("show_popup") === "true");
    };

    // Listen to storage events
    window.addEventListener("storage", handleStorageChange);

    // Clean up the listener when component unmounts
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [showPopup]);
  const closeModalPopup = () => {
    sessionStorage.setItem("show_popup", "false");
    setShowPopup(false);
    // navigate(0);
  };
  return (
    <div className="App">
      <ToastContainer zIndex={9999} />
      <AdSenseModal
        isOpen={showPopup}
        onRequestClose={() => closeModalPopup()}
      />
      {isAdmin ? (
        <div>
          {location.pathname !== "/" && <Admin_SideNav />}

          <Routes>
            <Route path="/admin_login" element={<Admin_login />} />
            {/* <Route path="/forget_password" element={<Admin_Forget_Password />} /> */}
          </Routes>
        </div>
      ) : (
        <div>
          {!isOnAdminLoginPage && !isOnIntroPage && <SideBar />}
          <Routes>
            <Route path="/admin_login" element={<Admin_login />} />
            <Route path="/intro" element={<IntroPage />} />

            {/* <Route path="/comic_viewer2" element={<User_Comic_Viewer2 />} />
          <Route path="/comic_viewer" element={<User_Comic_Viewer />} /> */}
            {/* <Route path="/" element={<SideBar />} /> */}
            {/* <Route path="/comic_viewer" element={<User_Comic_Viewer />} /> */}
            {/* <Route path="/forget_password" element={<Admin_Forget_Password />} /> */}
          </Routes>
        </div>
      )}
    </div>
  );
}

export default App;
