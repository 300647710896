// ImageWithLoader.js
import React, { useState, useEffect } from 'react';
import ComicSwirl from '../loaders/ComicSwirl';

const ImageWithLoader = ({ src, alt,dataIndex}) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const img = new Image();
    img.src = src;
    img.onload = () => setLoading(false);
  }, [src]);

  return (
    <div>
      {loading && (<center><ComicSwirl/></center>) }
      <img
        src={src}
        alt={alt}
        data-index={dataIndex + 1}
        style={{ display: loading ? 'none' : 'block' }}
      />
    </div>
  );
};

export default ImageWithLoader;
