import axios from "axios";
import React, { useEffect, useState } from "react";
import { a, useLocation, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { API_URL, IMG_URL } from "../../../Config";
import "./Show_Comic.css";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { common } from "@mui/material/colors";
import Cookies from "js-cookie";
import Button from "@mui/material/Button";
import en from "../../../assets/flag/cif-gb.svg";
import fr from "../../../assets/flag/cif-fr.svg";
import kr from "../../../assets/flag/cif-kr.svg";
import cn from "../../../assets/flag/cif-cn.svg";
import tr from "../../../assets/flag/cif-tr.svg";
import jp from "../../../assets/flag/cif-jp.svg";
import In from "../../../assets/flag/cif-in.svg";
import br from "../../../assets/flag/cif-br.svg";
import id from "../../../assets/flag/cif-id.svg";
import pl from "../../../assets/flag/cif-pl.svg";
import ru from "../../../assets/flag/cif-ru.svg";
import mx from "../../../assets/flag/cif-mx.svg";
import es from "../../../assets/flag/cif-es.svg";
import vn from "../../../assets/flag/cif-vn.svg";
import it from "../../../assets/flag/cif-it.svg";
import ua from "../../../assets/flag/cif-ua.svg";
import np from "../../../assets/flag/cif-np.svg";
import kz from "../../../assets/flag/cif-kz.svg";
import bd from "../../../assets/flag/cif-bd.svg";
import ro from "../../../assets/flag/cif-ro.svg";
import de from "../../../assets/flag/cif-de.svg";
import ar from "../../../assets/flag/cif-ar.svg";
import th from "../../../assets/flag/cif-th.svg";
import mn from "../../../assets/flag/cif-mn.svg";
import ir from "../../../assets/flag/cif-ir.svg";
import ie from "../../../assets/flag/cif-ie.svg";
import {
  AccessTimeRounded,
  BookmarkAddOutlined,
  FlagOutlined,
  ImportContactsOutlined,
  IosShareTwoTone,
} from "@mui/icons-material";
import { toast } from "react-toastify";
import { TextField } from "@mui/material";
import Slider from "react-slick";
import { RWebShare } from "react-web-share";
import useButtonClick from "../utils/utils";
import Adsense from "../adSense/Adsense";
import ComicSwirl from "../loaders/ComicSwirl";
import zIndex from "@mui/material/styles/zIndex";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime"
dayjs.extend(relativeTime)
const Show_Comic = () => {
  const [comic, setcomic] = useState({});
  const [similar, setsimilar] = useState([]);
  const [cover, setcover] = useState("");
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const [count6, clickButton6] = useButtonClick("button6");
  const [count7, clickButton7] = useButtonClick("button7");
  const [count8, clickButton8] = useButtonClick("button8");
  const [count9, clickButton9] = useButtonClick("button9");
  const [count10, clickButton10] = useButtonClick("button10");
  const [count11, clickButton11] = useButtonClick("button11");
  const [count12, clickButton12] = useButtonClick("button12");
  const { ids } = useParams();
  const [report, setreport] = useState({
    email: "",
    msg: "",
  });
  const images = {
    en: en,
    fr: fr,
    kr: kr,
    cn: cn,
    tr: tr,
    jp: jp,
    in: In,
    br: br,
    id: id,
    pl: pl,
    ru: ru,
    mx: mx,
    es: es,
    vn: vn,
    it: it,
    ua: ua,
    np: np,
    kz: kz,
    tm: In,
    bd: bd,
    ro:ro,
    de:de,
    ar:ar,
    th:th,
    mn:mn,
    ir:ir,
    ie:ie
  };
  const languages = {
    en: "English",
    fr: "French",
    kr: "Korean",
    cn: "Chinese",
    tr: "Turkey",
    jp: "Japanese",
    in: "Hindi",
    br: "Portuguese",
    id: "Indonesian",
    pl: "Polish",
    ru: "Russian",
    mx: "Mexican",
    es: "Spanish",
    vn: "Vietnamese",
    it: "Italian",
    ua: "Ukranian",
    np: "Nepali",
    kz: "Kazakh",
    tm: "Tamil",
    bd:"Bengali",
    ro:"Romanian",
    de:"German",
    ar:"Arabic",
    th:"Thai",
    mn:"Mongolian",
    ir:"Persian",
    ie:"Iris"
  };
  const sliderSettings = {
    dots: true,
    infinite: false,
    speed: 200,
    slidesToShow: 6,
    slidesToScroll: 3,
    arrows: false, // Remove arrows
    responsive: [
      {
        breakpoint: 2660,
        settings: {
          slidesToShow: 8,
          slidesToScroll: 3,
          infinite: false,
          dots: true,
          autoScroll: true,
          autoplay: true,
          speed: 1000,
          autoplaySpeed: 5000,
          cssEase: "linear",
        },
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 7,
          slidesToScroll: 3,
          infinite: false,
          dots: true,
          autoScroll: true,
          autoplay: true,
          speed: 1000,
          autoplaySpeed: 5000,
          cssEase: "linear",
        },
      },
      {
        breakpoint: 1350,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 2,
          infinite: false,
          dots: true,
          autoScroll: true,
          autoplay: true,
          speed: 1000,
          autoplaySpeed: 5000,
          cssEase: "linear",
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 2,
          infinite: false,
          dots: true,
          autoScroll: true,
          autoplay: true,
          speed: 1000,
          autoplaySpeed: 5000,
          cssEase: "linear",
        },
      },
      {
        breakpoint: 990,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
          infinite: false,
          dots: true,
          autoScroll: true,
          autoplay: true,
          speed: 1000,
          autoplaySpeed: 5000,
          cssEase: "linear",
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
          infinite: false,
          dots: true,
          autoScroll: true,
          autoplay: true,
          speed: 1000,
          autoplaySpeed: 5000,
          cssEase: "linear",
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
          autoScroll: true,
          autoplay: true,
          speed: 1000,
          autoplaySpeed: 5000,
          cssEase: "linear",
        },
      },
      {
        breakpoint: 495,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
          autoplay: true,
          speed: 1000,
          autoplaySpeed: 5000,
          cssEase: "linear",
        },
      },
    ],
  };

  const { pathname } = useLocation();

  // useEffect(()=>{
  //   window.scrollTo({ top: 0, behavior: "smooth" });
  // })

  // useEffect(()=>{
  //   window.scrollTo({ top: 0, behavior: "smooth" });
  // })

  const getGenreComic = async (genre) => {
    try {
      const data = await axios.post(`${API_URL}/comic/search/genre`, {
        page: 1,
        genre: genre,
      });
      setsimilar(data.data.data.slice(0, 10));
    } catch (error) {
      console.log(error);
    }
  };
  const addHistory = async (id,title) => {
    if (Cookies.get("UAuthorization")) {
      await axios.post(
        `${API_URL}/user/addHistory`,
        { id: id },
        { headers: { Authorization: Cookies.get("UAuthorization") } }
      );
    }
    navigate(`/comic/${title}`);
  };
  const getComic = async () => {
    try {
      setLoader(true);
      const data = await axios.get(`${API_URL}/comic/${ids}`);
      // console.log(data.data.data.coverImage);
      setcomic(data.data.data);
      setcover(`${IMG_URL}${data.data.data.coverImage}`);
      getGenreComic(data.data.data.genre.split(",")[0]);
      setLoader(false);
    } catch (error) {
      setLoader(false);

      console.log(error);
    }
  };
  const savecomic = async (id) => {
    try {
      if (Cookies.get("UAuthorization")) {
        const save = await axios.post(
          `${API_URL}/user/comic/${id}`,
          { comic: "save" },
          { headers: { Authorization: Cookies.get("UAuthorization") } }
        );
        if (save.data.status == "success") {
          toast.success(`${save.data.message}`);
        }
      } else {
        navigate("/my/library");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const doReport = async (e) => {
    e.preventDefault();
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (emailRegex.test(report.email) && report.msg !== "") {
      const sendReport = await axios.post(`${API_URL}/user/report`, { report });
      if (sendReport.data.status === "success") {
        toast.success("Report Submited");
      } else {
        toast.error("Report Filed Not Satisfiedable");
      }
    } else {
      toast.warn("Enter required Field");
    }
  };
  const copya = () => {
    window.Clipboard.bind(window.location.toString());
    toast.success("a Copy");
  };

  const comicView = (id) => {
    if (id) {
      navigate(`/comic_viewer2/${id}`);
    }
  };
  useEffect(() => {
    getComic();
  }, [ids]);

  return (
    <div id="show_comic">
      {loader ? (
        <div
          Style="    width: 100%;
                      height: 100vh;
                      display: flex;
                      justify-content: center;
                      align-items: center;"
        >
          <ComicSwirl />
        </div>
      ) : (
        <div>
          <ComicSwirl />
          {/* <div className="hero" style={{ background: `url('${cover}')` }}></div> */}
          <img src={cover} className="hero" alt="" />
          <div
          // className="hero"
          // style={{ background : `url('${cover}')` }}
          ></div>
          <div className="outer_main">
            <div className="outer_main_image">
              <img
                src={cover}
                alt={comic?.title}
                height={300}
                width={200}
                style={{ borderRadius: "5px" }}
              />
            </div>
            <div className="comic_title_container">
              <div className="comic_title1">{comic.title}</div>
              <div className="comic_title2">
                {comic.description}
                <br />
              </div>
              <div
                className="comic_title3"
                Style="font-size:16px;font-family:roboto,sans-serif;margin-bottom:0rem"
              >
                Author : {comic.author}
                <br />
              </div>
              <div
                // style={{ display: "flex", gap: "6px", position: "absolute" , top : "40%" , left : "23%" }}
                className="genres"
                style={{ display: "flex", gap: "6px", flexWrap: "wrap" }}
              >
                {comic.genre &&
                  comic.genre.split(",").slice(0,7).map((data, i) => {
                    if (i == 0) {
                      return (
                        <a
                          className="btn genre_title"
                          href={`/genre/${data}`}
                          style={{
                            backgroundColor: "#da8ee7",
                            color: "white",
                            padding: "0",
                            paddingLeft: "10px",
                            paddingRight: "10px",
                            fontSize: ".8em",
                            fontFamily: "poppins",
                            fontWeight: "600",
                          }}
                        >
                          {data}
                        </a>
                      );
                    } else {
                      return (
                        <a
                          className="btn genre_title"
                          href={`/genre/${data}`}
                          style={{
                            backgroundColor: "#2C2C2C",
                            color: "white",
                            padding: "0",
                            paddingLeft: "10px",
                            paddingRight: "10px",
                            fontSize: ".8em",
                            fontFamily: "poppins",
                            fontWeight: "600",
                          }}
                        >
                          {data}
                        </a>
                      );
                    }
                  })}
              </div>
            </div>
          </div>
          <div className="comic_content_container">
            <div className="h-full">
              <div className="d-flex flex-wrap save_buttons_container">
                <button
                  className="mt-1 savebutton"
                  onClick={() => {
                    comicView(comic?.chapter[0]?.chapterId?.lang[0]?._id);
                  }}
                  onMouseUp={() => clickButton6()}
                >
                  <ImportContactsOutlined /> Start Reading
                </button>
                <button
                  className="mt-1 reportButton"
                  onClick={() => {
                    savecomic(comic._id);
                    clickButton7();
                  }}
                >
                  <BookmarkAddOutlined /> Add To Library
                </button>
                <button
                  data-bs-toggle="modal"
                  data-bs-target="#doreport"
                  className="mt-1 reportButton"
                  title="Report"
                  onClick={() => {
                    clickButton8();
                  }}
                >
                  <FlagOutlined /> Report
                </button>
                <RWebShare
                  data={{
                    text: "Web Share - comic",
                    url: window.location.toString(),
                    title: "COMIC",
                  }}
                  onClick={() => {
                    clickButton9();
                  }}
                >
                  <button
                    className="mt-1 reportButton"
                    onClick={() => {
                      clickButton10();
                    }}
                    title="Share"
                  >
                    <IosShareTwoTone /> Share
                  </button>
                </RWebShare>
              </div>
              <center>
                <div className="comic_ad_container1">
                  <Adsense responsive={true} dataAdSlot="" />
                </div>
              </center>
              <div
                Style="font-family:Poppins;"
                className="mt-20"
                style={{ marginTop: "60px" }}
              >
                {comic.chapter &&
                  comic.chapter.map((data, index) => {
                    return (
                      <div>
                        {index !== 0 && index % 6 == 0 ? (
                          <center key={index}>
                            <div className="dashboard_ad_container2 mt-3 mb-3">
                              <Adsense responsive={true} dataAdSlot="" />
                            </div>
                          </center>
                        ) : null}
                        <div>
                          <Accordion
                            key={index}
                            defaultExpanded
                            Style="background-color:#191a1c"
                          >
                            <AccordionSummary
                              expandIcon={
                                <ExpandMoreIcon
                                  sx={{ color: common["white"] }}
                                />
                              }
                              aria-controls="panel1-content"
                              id="panel1-header"
                            >
                              <p className="text-white">
                                {data.no} {data.chapterId?.title}
                              </p>
                            </AccordionSummary>

                            <AccordionDetails>
                              {data.chapterId.lang &&
                                data.chapterId.lang.map((ele, i) => {
                                  return (
                                    <div
                                      key={i}
                                      className="d-md-flex d-sm-block d-xs-block  justify-content-between h-25 mb-1 "
                                      Style="background-color:#2c2c2c;padding: 14px 18px;cursor:pointer"
                                      onClick={() =>
                                        navigate(`/comic_viewer2/${ele._id}`)
                                      }
                                    >
                                      <div style={{ cursor: "pointer" }}>
                                        <img
                                          src={images[ele.name]}
                                          height={20}
                                          style={{marginRight : "8px" , width :"38px"}}
                                        />{" "}
                                        <span className="text-white">
                                          {languages[ele.name]}{data.chapterId?.title  ? " - " : ""} {" "}
                                          {data.chapterId?.title}
                                        </span>
                                      </div>
                                      <div>
                                        <AccessTimeRounded
                                          sx={{ color: common["white"] }}
                                        />{" "}
                                        <span className="text-white">
                                          {dayjs(
                                            data.chapterId.createdAt
                                          ).fromNow()}
                                        </span>
                                      </div>
                                    </div>
                                  );
                                })}
                            </AccordionDetails>
                          </Accordion>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
            <div className="my-5 " Style="margin-bottom:4rem">
              <h4 className="text-white mb-5">
                Similar Comics :-{" "}
               {/* <span className="fw-medium " style={{ color: "#da8ee7" }}>
                  {comic?.title}
                </span>*/}
              </h4>
              {similar.length > 0 ? (
                <Slider {...sliderSettings}>
                  {similar.map((card, index) => (
                    <div>
                      <div
                        key={index}
                        onClick={() => addHistory(card._id,card.title)}
                        Style="cursor:pointer"
                        className="card recently-card"
                      >
                        <img
                          src={`${IMG_URL}${card.coverImage}`}
                          className="card-img-top"
                          style={{ height: "200px" }}
                          alt="..."
                        />
                      </div>

                      <h6 className="comicName  mt-2 text-sm text-white line-clamp-2">
                        {card?.title}
                      </h6>
                    </div>
                  ))}
                </Slider>
              ) : (
                <center></center>
              )}
            </div>
          </div>
          <div
            className="modal fade newmodal text-white "
            id="doreport"
            tabIndex="-1"
            aria-labelledby="doreport"
            aria-hidden="true"
            data-bs-theme="dark"
          >
            <div className="modal-dialog text-white">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Report Here
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <form>
                  <div className="modal-body">
                    <TextField
                      style={{ color: "white" }}
                      id="outlined-basic"
                      label="Your Email"
                      variant="outlined"
                      type="email"
                      fullWidth
                      name="name"
                      value={report.email}
                      onChange={(e) =>
                        setreport({ ...report, email: e.target.value })
                      }
                    />
                    <TextField
                      style={{ color: "white" }}
                      id="outlined-basic"
                      label="Message"
                      variant="outlined"
                      fullWidth
                      name="name"
                      className="mt-2"
                      value={report.msg}
                      onChange={(e) =>
                        setreport({ ...report, msg: e.target.value })
                      }
                    />
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-bs-dismiss="modal"
                      onClick={() => {
                        clickButton11();
                      }}
                    >
                      Close
                    </button>
                    <button
                      className="btn btn-primary"
                      onClick={(e) => {
                        clickButton12();
                        doReport(e);
                      }}
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Show_Comic;
