import React, { useEffect, useState } from "react";
import { IMG_URL, API_URL } from "../../../Config";
import { Pagination } from "@mui/material";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import ComicSwirl from "../loaders/ComicSwirl";
const AdvancedSearch = () => {
  const [comic, setcomic] = useState([]);
  const [page, setpage] = useState(1);
  const [pageno, setpageno] = useState(1);
  const [load, setload] = useState(false);
  const navigate = useNavigate();
  const getTheLatestUpdatedComic = async (page, search) => {
    try {
      setload(true);
      let query = {
        page: page,
        type: "latestUpdate",
        search: search,
      };
      // query["search"] = search;
      const data = await axios.post(`${API_URL}/comic/search`, query);
      setcomic(data.data.data);
      setpage(Math.ceil(data.data.totalData / 30));
      setload(false);
    } catch (error) {
      // console.log(error);
    }
  };
  const changePage = (event, value) => {
    // console.log(event,value);
    setload(true);
    setpageno(value);
    getTheLatestUpdatedComic(value, "");
    setload(false);
  };
  const searchComic = async (e, value) => {
    getTheLatestUpdatedComic(1, value);
  };
  useEffect(() => {
    getTheLatestUpdatedComic(pageno, "");
  }, []);

  return (
    <div className="text-white">
      <div className="text-white">
        <div className="mt-5 mb-3 pt-2">
          <div
            className="back text-white d-flex justify-content-between "
            style={{ width: "90%", margin: "20px auto" }}
          >
            <h2>Advanced Search</h2>
            <div class="d-flex" role="search" style={{ height: "2.5rem" }}>
              <input
                class="form-control me-2"
                type="search"
                placeholder="Search Here..."
                aria-label="Search"
                onChange={(e) => searchComic(e, e.target.value)}
              />
            </div>
          </div>
          {/*<div className="test">
              <div className="grid-item">
                 <img src="http://localhost:8080//abc/Nottingham%20012-0004.jpg"/> 
                 
              </div>
              <div className="grid-item">
                 <img src="http://localhost:8080//abc/Nottingham%20012-0004.jpg"/> 
                 
              </div>
              <div className="grid-item">
                 <img src="http://localhost:8080//abc/Nottingham%20012-0004.jpg"/> 
                 
              </div>
              <div className="grid-item">
                 <img src="http://localhost:8080//abc/Nottingham%20012-0004.jpg"/> 
                 
              </div>
              <div className="grid-item">
                 <img src="http://localhost:8080//abc/Nottingham%20012-0004.jpg"/> 
                 
              </div>
      </div>*/}
          {load ? (
            <center>
              <ComicSwirl />
            </center>
          ) : (
            <div>
              <div className="showLibrary">
                {comic != [] ? (
                  comic.map((data, index) => {
                    return (
                      <div
                        key={index}
                        className="grid-item"
                        onClick={() => navigate(`/comic/${data.title}`)}
                        data-text={data.title}
                      >
                        <img src={`${IMG_URL}${data.coverImage}`} />
                      </div>
                    );
                  })
                ) : (
                  <div>Comics Not Found</div>
                )}
              </div>
              <div className="text-white text-center pagination">
                <Pagination
                  count={page}
                  page={pageno}
                  defaultPage={pageno}
                  onChange={changePage}
                  color="secondary"
                  showFirstButton
                  showLastButton
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdvancedSearch;
