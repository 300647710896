import React, { useRef, useEffect, useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import DataTable from "datatables.net-dt"; // Assuming DataTable is correctly imported
import $, { get } from "jquery";
import "datatables.net-responsive";
import "../Admin_Genre/Admin_Genre.css";
import TextField from "@mui/material/TextField";
import { toast } from "react-toastify";
import ReactDOM from "react-dom";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import {API_URL,IMG_URL} from "../../../Config";
import { AddAPhoto } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import Loader from "../Loader/Loader";
const Admin_Add_Comics = () => {
  const [get_genre, set_genre] = useState([]);
  const tableRef = useRef(null);
  const [get_comics, set_comics] = useState([]);
  const [loader, setLoader] = useState(false);

  const navigate = useNavigate();
  const handle_get_comic = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `${Cookies.get("Authorization")}`,
    };

    try {
      const response = await axios.get(`${API_URL}/comic`, {
        headers,
      });
      if (response.data.status === "success") {
        set_comics(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching genre data:", error);
    }
  };
  const handle_get_genre = async () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `${Cookies.get("Authorization")}`,
    };

    try {
      const response = await axios.get(`${API_URL}/genre`, {
        headers,
      });
      if (response.data.status === "success") {
        set_genre(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching genre data:", error);
    }
  };

  const addchapter = (id)=>{
    navigate(`/chapter?id=${id}`)
  }
  useEffect(() => {
    const Authorization = Cookies.get("Authorization");
    const token = Authorization?.split(" ")[1];
    if(!token){
      navigate("/admin_login")
    }
    handle_get_comic();
    handle_get_genre();
  
  }, []);

  // useEffect(() => {
  //   if (get_comics.length > 0 && !tableRef.current) {
  //     // Initialize the DataTable with checkboxes
  //     const tableInstance = $("#myTable").DataTable({
  //       responsive: true,
  //       data: get_comics,
  //       columns: [
  //         { title: "Title", data: "title" },
  //         { title: "coverImage", data: null },
  //         { title: "Genre", data: "genre" },
  //         { title: "author", data: "author" },
  //         { title: "Actions", data: null },
  //         // Add more columns based on your data
  //       ],
  //       columnDefs: [
  //         {
  //           targets: 1,
  //           data: null,
  //           defaultContent: "",
  //           orderable: false,
  //           createdCell: function (td, cellData, rowData, row, col) {
  //             ReactDOM.render(
  //               <>
  //               <div>ssssssssss</div>
  //                 {/* <img
  //                   src={"file:///g%3A/comic_project/comics_backend/public/aa/profile_img1.png"}
  //                   alt="aaaa"
  //                 /> */}
  //               </>,
  //               td
  //             );
  //           },
  //         },
  //       ],
  //       columnDefs: [
  //         {
  //           targets: -1,
  //           data: null,
  //           defaultContent: "",
  //           orderable: false,
  //           createdCell: function (td, cellData, rowData, row, col) {
  //             ReactDOM.render(
  //               <>
  //                 <button
  //                   className="btn btn-primary "
  //                   data-bs-toggle="modal"
  //                   data-bs-target="#editgenre"
  //                   onClick={() => handle_get_genre_name(rowData)}
  //                   style={{ marginRight: "6px" }}
  //                 >
  //                   <EditIcon />
  //                 </button>

  //                 <button
  //                   className="btn btn-danger"
  //                   onClick={(e) => {
  //                     handle_delete_genre(e, rowData._id);
  //                   }}
  //                 >
  //                   <DeleteIcon />
  //                 </button>
  //               </>,
  //               td
  //             );
  //           },
  //         },
  //       ],
  //     });
  //     tableRef.current = tableInstance;
  //   }

  //   return () => {
  //     if (tableRef.current) {
  //       tableRef.current.destroy();
  //       tableRef.current = null;
  //     }
  //   };
  // }, [get_comics]);
  const handle_check_is_popular = async (e,id,ischecked) => {
    // console.log(ischecked);
    const final_check = !ischecked
    // console.log(final_check)
    const headers = {
      "Content-Type": "application/json",
      Authorization: `${Cookies.get("Authorization")}`,
    };
    await axios.put(`${API_URL}/comic/popularTop/${id}`,{
      isPopular:final_check,
    },{ headers: headers }).then((res)=>{
      if (res.data.status == "success") {
        toast.success(`${res.data.message}`);
        navigate(0)
      } else {
        toast.success(`${res.data.message}`);
      }
    })
  };

  const handle_check_is_top10 = async (e,id,ischecked) => {
    // console.log(ischecked);
    const final_check = !ischecked
    // console.log(final_check)
    const headers = {
      "Content-Type": "application/json",
      Authorization: `${Cookies.get("Authorization")}`,
    };
    await axios.put(`${API_URL}/comic/popularTop/${id}`,{
      isTop10:final_check,
    },{ headers: headers }).then((res)=>{
      if (res.data.status == "success") {
        toast.success(`${res.data.message}`);
        navigate(0)
      } else {
        toast.success(`${res.data.message}`);
      }
    })

  }
  useEffect(() => {
    if (get_comics.length > 0 && !tableRef.current) {
      // Initialize the DataTable with checkboxes
      const tableInstance = $("#myTable").DataTable({
        // responsive: true,
        data: get_comics,
        columns: [
          { title: "Popular", data: null },
          { title: "Top 10", data: null },
          { title: "Title", data: "title" },
          { title: "Cover Image", data: null },
          { title: "Genre", data: "genre" },
          { title: "Author", data: "author" },
          { title: "Actions", data: null },
        ],
        columnDefs: [
          {
            targets: 3, // Target the second column for the image
            orderable: false,
            createdCell: function (td, cellData, rowData, row, col) {
              ReactDOM.render(
                <img
                  src={`${IMG_URL}${rowData.coverImage}`} // Use the correct field from your data
                  alt={rowData.title}
                  style={{ width: "50px", height: "50px" }}
                />,
                td
              );
            },
          },
          
          {
            targets: 0, // Target the second column for the image
            orderable: false,
            createdCell: function (td, cellData, rowData, row, col) {
              ReactDOM.render(
                <>
                  <input
                    type="checkbox"
                    onClick={(e) => handle_check_is_popular(e,rowData._id,rowData.isPopular)}
                    // value={get_comics.isPopular}
                    checked={rowData.isPopular ?true:false}

                  />
                </>,
                td
              );
            },
          },
          {
            targets: 1, // Target the second column for the image
            orderable: false,
            createdCell: function (td, cellData, rowData, row, col) {
              ReactDOM.render(
                <>
                  <input
                    type="checkbox"
                    onClick={(e) => handle_check_is_top10(e,rowData._id,rowData.isTop10)}
                    // value={get_comics.isPopular}
                    checked={rowData.isTop10 ?true:false}

                  />
                </>,
                td
              );
            },
          },
          {
            targets: -1, // Target the last column for actions
            orderable: false,
            createdCell: function (td, cellData, rowData, row, col) {
              ReactDOM.render(
                <>
                  <button
                    className="btn btn-primary m-2"
                    data-bs-toggle="modal"
                    data-bs-target="#editgenre"
                    onClick={() => handle_get_genre_name(rowData)}
                    style={{ marginRight: "6px" }}
                  >
                    <EditIcon />
                  </button>
                  <button
                    className="btn btn-primary m-2"
                    onClick={() => addchapter(rowData._id)}
                    style={{ marginRight: "6px" }}
                  >
                    <AddAPhoto/>
                  </button>
                  <button
                    className="btn btn-danger m-2 "
                    onClick={(e) => {
                      handle_delete_comics(e, rowData._id);
                    }}
                  >
                    <DeleteIcon />
                  </button>
                </>,
                td
              );
            },
          },
        ],
      });
      tableRef.current = tableInstance;
    }

    return () => {
      if (tableRef.current) {
        tableRef.current.destroy();
        tableRef.current = null;
      }
    };
  }, [get_comics]);

  const genreRef = useRef();

  const comic_cover_imageRef = useRef();
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };
  const handle_submit_comics = (e) => {
    e.preventDefault();
    setLoader(true);

    // console.log(Cookies.get("Authorization"));
    const headers = {
      "Content-Type": "multipart/form-data",
      Authorization: `${Cookies.get("Authorization")}`,
    };

    const formData = new FormData();
    // formData.append("cover", comic_cover_imageRef.current.files[0]);
    formData.append("cover", selectedFile);
    formData.append("title", data.title);
    formData.append("description", data.description);
    formData.append("genre", selectedGenres);
    formData.append("author", data.author);

    axios
      .post(`${API_URL}/comic`, formData, {
        headers: headers,
      })
      .then((res) => {
        if (res.data.status == "success") {
          toast.success(`${res.data.message}`);
          navigate(0);
    setLoader(false);
        } else {
          toast.success(`${res.data.message}`);
    setLoader(false);

        }
      });
  };

  const handle_delete_comics = (e, id) => {
    e.preventDefault();
    setLoader(true);

    const headers = {
      "Content-Type": "application/json",
      Authorization: `${Cookies.get("Authorization")}`,
    };

    const ans = window.confirm("Are You Sure...");
    if (ans) {
      axios
        .delete(`${API_URL}/comic/${id}`, {
          headers: headers,
        })
        .then((res) => {
          if (res.data.status == "success") {
            toast.success(`${res.data.message}`);
            navigate(0)
    setLoader(false);

          } else {
            toast.success(`${res.data.message}`);
    setLoader(false);

          }
        });
    }
  };

  const [get_genrearr, set_genrearr] = useState([]);

  const handle_get_genre_name = (name) => {
    const genrearr = name.genre.split(",");
    setSelectedGenres(genrearr);
    setData(name);
  };
  const handle_edit_comics = async (e) => {
    e.preventDefault();
    setLoader(true);

    const headers = {
      "Content-Type": "multipart/form-data",
      Authorization: `${Cookies.get("Authorization")}`,
    };
    // console.log("ppppppppppppppppp", selectedGenres);
    const genress = selectedGenres.join(",");
    // console.log(genress);
    const formData = new FormData();
    formData.append("cover", selectedFile);
    formData.append("title", data.title);
    formData.append("description", data.description);
    formData.append("genre", genress);
    formData.append("author", data.author);

    axios
      .put(`${API_URL}/comic/${data._id}`, formData, {
        headers: headers,
      })
      .then((res) => {
        if (res.data.status == "success") {
          toast.success(`${res.data.message}`);
          navigate(0);
    setLoader(false);
        } else {
          toast.success(`${res.data.message}`);
    setLoader(false);

        }
      });
  };

  const [data, setData] = useState({ name: "" });

  const handle_change_input = async (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const [selectedGenres, setSelectedGenres] = useState([]);

  // const handle_selected_genre = (e)=>{
  //     setSelectedGenre([...selectedGenre , e.target.value])
  // }

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;

    setSelectedGenres(
      (prevSelectedGenres) =>
        checked
          ? [...prevSelectedGenres, value] // Add value to array if checked
          : prevSelectedGenres.filter((genre) => genre !== value) // Remove value from array if unchecked
    );
  };

  return (
    <div className="container">
      {loader ? <Loader /> : null}

      <div className="row">
        <div className="col" style={{ paddingTop: "20px" }}>
          <h2>
            {" "}
            <button
              type="button"
              className="btn btn-primary add_genre_btn"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
            >
              Add New Comics
            </button>
            Comics
          </h2>
          <table
            id="myTable"
            className="table table-striped table-bordered"
            style={{ width: "100%" }}
          >
            <thead>
              <tr>
                <th>Comics</th>
                {/* Add more table headers if needed */}
              </tr>
            </thead>
          </table>
        </div>

        {/* <!-- Modal --> */}
        <div
          className="modal fade"
          id="exampleModal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Add Comics
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <form
                onSubmit={(e) => {
                  handle_submit_comics(e);
                }}
                method="post"
                encType="multipart/form-data"
              >
                <div className="modal-body">
                  <TextField
                    id="outlined-basic"
                    label="Title"
                    variant="outlined"
                    fullWidth
                    name="title"
                    onChange={(e) => handle_change_input(e)}
                    className="mb-3"
                  />
                  <TextField
                    id="outlined-basic"
                    label="Description"
                    variant="outlined"
                    fullWidth
                    name="description"
                    onChange={(e) => handle_change_input(e)}
                    className="mb-3"
                  />
                  <TextField
                    id="outlined-basic"
                    label="Author"
                    variant="outlined"
                    fullWidth
                    name="author"
                    onChange={(e) => handle_change_input(e)}
                    className="mb-3"
                  />
                  <p
                    style={{
                      fontSize: "1em",
                      textAlign: "start",
                      fontWeight: "500",
                    }}
                  >
                    Genre:-
                  </p>
                  {get_genre.map((data1) => {
                    return (
                      <label className="mb-3">
                        <input
                          type="checkbox"
                          name="genre"
                          //   onChange={(e) => handle_change_input(e)}
                          onChange={handleCheckboxChange}
                          value={data1.name}
                          style={{ marginRight: "5px", marginLeft: "10px" }}
                        />
                        {data1.name}
                      </label>
                    );
                  })}
                  <p
                    style={{
                      fontSize: "1em",
                      textAlign: "start",
                      fontWeight: "500",
                    }}
                  >
                    Cover Imge:-
                  </p>
                  <input
                    type="file"
                    name="cover"
                    className="form-control"
                    onChange={handleFileChange}
                  />
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                  <button type="submit" className="btn btn-primary">
                    Add Comics
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        {/* <!--Edit Genre Modal --> */}
        <div
          className="modal fade"
          id="editgenre"
          tabIndex="-1"
          aria-labelledby="editgenre"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Edit Comics
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <form
                onSubmit={(e) => {
                  handle_edit_comics(e);
                }}
                method="post"
                encType="multipart/form-data"
              >
                <div className="modal-body">
                  <TextField
                    id="outlined-basic"
                    label="Title"
                    variant="outlined"
                    fullWidth
                    name="title"
                    onChange={(e) => handle_change_input(e)}
                    className="mb-3"
                    value={data.title}
                  />
                  <TextField
                    id="outlined-basic"
                    label="Description"
                    variant="outlined"
                    fullWidth
                    name="description"
                    onChange={(e) => handle_change_input(e)}
                    className="mb-3"
                    value={data.description}
                  />
                  <TextField
                    id="outlined-basic"
                    label="Author"
                    variant="outlined"
                    fullWidth
                    name="author"
                    onChange={(e) => handle_change_input(e)}
                    className="mb-3"
                    value={data.author}
                  />
                  <p
                    style={{
                      fontSize: "1em",
                      textAlign: "start",
                      fontWeight: "500",
                    }}
                  >
                    Genre:-
                  </p>

                  {/* {get_genre.map((data1,i) => {
                    console.log("ssssssssssss",get_genrearr[i])
                    return (
                      <label className="mb-3">
                        <input
                          type="checkbox"
                          name="genre"
                        //   onChange={(e) => handle_change_input(e)}
                        onChange={handleCheckboxChange}
                        value={data1.name}
                          style={{ marginRight: "5px" , marginLeft:"10px" }}
                          checked={get_genrearr[i] === data1.name ? "true" : false}
                        />
                        {data1.name}
                      </label>
                    );
                  })} */}
                  {get_genre.map((data1, i) => {
                    // console.log("ssssssssssss", get_genrearr[i]);
                    return (
                      <label className="mb-3" key={i}>
                        <input
                          type="checkbox"
                          name="genre"
                          onChange={handleCheckboxChange}
                          value={data1.name}
                          style={{ marginRight: "5px", marginLeft: "10px" }}
                          checked={
                            selectedGenres.includes(data1.name) ? "true" : null
                          }
                        />
                        {data1.name}
                      </label>
                    );
                  })}

                  <p
                    style={{
                      fontSize: "1em",
                      textAlign: "start",
                      fontWeight: "500",
                    }}
                  >
                    Cover Imge:-
                  </p>
                  <input
                    type="file"
                    name="cover"
                    className="form-control"
                    onChange={handleFileChange}
                  />
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                  <button type="submit" className="btn btn-primary">
                    Edit Comic
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
    
export default Admin_Add_Comics;
