import React from 'react'
import './Loader.css'
const Loader = () => {
  return (
    <div>
        <div className="loader">
	<div className="loader-inner">
		<div className="loader-line-wrap">
			<div className="loader-line"></div>
		</div>
		<div className="loader-line-wrap">
			<div className="loader-line"></div>
		</div>
		<div className="loader-line-wrap">
			<div className="loader-line"></div>
		</div>
		<div className="loader-line-wrap">
			<div className="loader-line"></div>
		</div>
		<div className="loader-line-wrap">
			<div className="loader-line"></div>
		</div>
	</div>
</div>
    </div>
  )
}

export default Loader