import React, { useEffect, useState } from "react";
import "./IntroPage.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../../../Config";
import Adsense from "../adSense/Adsense";
import LOGO from "../../../assets/1.png";
const IntroPage = () => {
  const [click, setclick] = useState(false);
  const [genre, setgenre] = useState([]);
  const navigate = useNavigate();
  const getrandomGenre = async () => {
    try {
      const data = await axios.get(`${API_URL}/genre/random`);
      // console.log(data.data.data[0]);
      setgenre(data.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getrandomGenre();
    // console.log(genre[2]);
  }, []);

  return (
    <center
      className="intopage_main_container intopage_main_container1 h-100"
      style={{ height: "100vh !important" }}
    >
      <div className="text-white text-center"  style={{ position : "absolute" , top : "0" }}>
        <img
          src={LOGO}
          alt="MANGAINSPO"
          className="intro_logo"
        />
        <div
          className="intro_txt1"
        >
          MANGA<span className="" style={{color : "white"}} >INSPO</span>
        </div>
        <div style={{ display : 'flex' , justifyContent : "center" }} >
        <div
          className="intro_ad mt-2"
        
        >
          <Adsense responsive={true} dataAdSlot="" />
        </div>

        </div>
        <div
          className="text-center"
         
        >
          <h4 className="my-4">Select Your Favorite Comic Category !!!</h4>
          <div
            className="button-grid"
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(2, 1fr)",
              gap: "1rem",
              justifyItems: "center",
              alignItems: "center",
            }}
          >
            {genre.map((data, index) => {
              return (
                <button
                  key={index}
                  style={{ width: "150px", height: "50px" }}
                  className="button m-1"
                  title="Select Me As Your Favourite Category"
                  onClick={() => setclick(true)}
                >
                  {data.name}
                </button>
              );
            })}
          </div>
        </div>
        <div
          style={{}}
          Style="position : relative; margin-top : 3%"
        >
          <button
            className="btn"
            disabled={click ? false : true}
            onClick={() => navigate("/", { replace: true })}
            style={{backgroundColor : "white" , color : "black"}}
          >
            {" "}
            Start Reading Now !!
          </button>
        </div>
        
        <div
          // style={{
          //   width : "20%",
          //   display : "flex",
          //   flexWrap : "wrap",
          //   position : "absolute",
          //   top : "98%",
          //   left : "50%",
          //   transform : "translate(-50%,-50%)",
          //   gap : "20px"

          // }}
          className="intro_other_link"
        >
          <h6>
            <a
              href="/term_and_condition"
              Style="color:white;"
            >
              Term And Conditions
            </a>
          </h6>
          <h6>
            <a
              href="/privacy_policies"
              Style="color:white;"
            >
              Privacy Policies
            </a>
          </h6>
        </div>
      </div>
    </center>
  );
};

export default IntroPage;
