// <<<<<<< Updated upstream
// export const API_URL = "http://localhost:8080/api/v1"
// export const IMG_URL = "http://localhost:8080/storage/" 


export const API_URL = "https://mangainspo.com/api/v1"
export const IMG_URL = "https://mangainspo.com/storage/"

// export const API_URL = "https://comics-backend.onrender.com/api/v1"
// export const IMG_URL = "https://comics-backend.onrender.com/storage/"
// >>>>>>> Stashed changes  
